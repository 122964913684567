import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/festl-oberaudorf-preview.jpg'
import presseImage1 from '@images/presse/Festl_Tour_Impressionen_1.jpg'
import presseImage2 from '@images/presse/Festl_Tour_Impressionen_2.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12 xs:col-span-6`

const PressePage = () => {
  const presseImage = '/download/festl-oberaudorf.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Ausgelassen feiern mit mitreißender Live-Musik und bayerischen Schmankerln!
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Die Alpin FM Festl Tour mit ERDINGER Brauhaus macht Station in Oberaudorf
                  </strong>
                </p>
                <p>
                  Erding, Juli 2023<br />
                  Die Alpin FM Festl Tour mit ERDINGER Brauhaus geht weiter. Nach
                  dem grandiosen Start der diesjährigen Tour in Bad-Reichenhall und der legendären
                  Veranstaltung 2022 in Oberaudorf freut sich die Tour auch im Sommer 2023 wieder in der
                  „Perle des Inntals“ zu Gast sein zu dürfen.
                </p>
                <p>
                  Am Sonntag, den 16. Juli, verwandelt sich der idyllische Kurpark in Oberaudorf (Bad-Trißl Straße 4)
                  ab 14 Uhr zur Festival-Location. Die bayerischen Top-Bands Strawanza, Guten A-Band, Dis M und Fenzl
                  sorgen mit Live-Musik für ordentlich Stimmung. Nachmittags geht’s
                  zuerst noch gemütlich los. Es gibt Kaffee und Kuchen sowie leckere Fischsemmeln von der
                  Audorfer Frauengemeinschaft e.V., die den daraus entstandenen Erlös auch in diesem Jahr
                  wieder für den guten Zweck spendet. Die Bergwacht Oberaudorf fährt mit Grillschmankerln
                  auf und auch für alle durstigen Gäste ist natürlich gesorgt. Das ERDINGER Brauhaus Team
                  schenkt gemeinsam mit der Luegstock e.V. alkoholfreie Getränke und ERDINGER Brauhaus
                  Spezialitäten aus – da bleibt keine Kehle trocken! Im Laufe des Abends, wenn die Stimmung
                  immer ausgelassener wird, heizen die Bands dann richtig ein. Es wird gemeinsam getanzt,
                  gesungen und angestoßen. Jeder ist herzlich eingeladen mitzufeiern – der Eintritt ist frei.
                  Alpin FM, alle Bands, Partner und ERDINGER Brauhaus freuen sich auf zahlreiche Gäste.
                </p>
                <p>
                  Nach dem Tour-Start am Sonntag, den 18. Juni, in Bad Reichenhall und der zweiten Station
                  in Oberaudorf am Sonntag, den 16. Juli, geht’s an folgenden Standorten weiter:
                </p>
                <ul class="list-disc text-white ml-8 text-18px">
                  <li>Sonntag, 30. Juli – Ruhpolding</li>
                  <li>Sonntag, 27. August – Erding</li>
                </ul>
                <p>
                  Homepage: <a href="https://www.erdinger-brauhaus.de/events/" target="_blank">www.erdinger-brauhaus.de/events/</a><br />
                  Instagram: <a href="https://www.instagram.com/erdinger.brauhaus/" target="_blank">@erdinger.brauhaus</a>
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImage} 
                      target="_blank"
                    >
                      <img src={pressePreviewImage} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <ImageSource>Bildquelle: Darius Kappes / ERDINGER Weißbräu.</ImageSource>
                </div>
              </ContentImageWrapper>

              <br />
              <ContentRow>
                <ContentRowImage>
                  <img src={presseImage1} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                </ContentRowImage>
                <ContentRowImage>
                  <img src={presseImage2} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                </ContentRowImage>
              </ContentRow>
              <p tw="text-center">Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus</p>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
